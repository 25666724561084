<template>
    <div class="container">
        <div class="home">
            <div class="header-style-bg">
                <keyboard ref="child" @confirmBtnFn="confirmBtnFn" :licensePlate="carNumber"/>
                <van-button class="cx" type="primary" size="normal" @click="getUserCenterList">查询</van-button>
                <div class="title">
                    <p>历史缴费记录</p>
                    <p style="color: #82c276" @click="handleClickETC">开通ETC缴费</p>
                </div>
                <van-grid :gutter="10" :border="false" :column-num="3">
                    <van-grid-item @click="onConfirm(columns[index])" v-for="(value,index) in shadow" :key="index"
                                   :text="columns[index]"/>
                </van-grid>
            </div>
            <div class="header-style-text">
                <span class="p-text2">温馨提示：</span>
                <span class="p-text2">临时车缴费后请在规定的时间内出场，否则超时后将再次收缴费用；</span>
                <span class="p-text2">无牌车辆直接出口缴费</span>
            </div>
            <div class="bottom-style-text">
                <span class="p-text2">Copyright@2023 All Rights Reserved.</span>
            </div>
        </div>
        <van-popup v-model="showPop" class="ad-popup" :close-on-click-overlay="false">
            <div style="margin-bottom: 20px;font-size: 18px;color: white;">关闭弹窗，进入缴费页面</div>
            <van-swipe :autoplay="3000">
                <van-swipe-item v-for="(image, index) in adImg" :key="index">
                    <img v-lazy="image.url" @click="handleClick"/>
                </van-swipe-item>
            </van-swipe>
            <van-icon style="margin-top: 20px" size="40px" name="clear" @click="showPop = false"/>
        </van-popup>
        <div class="overlay-box" v-show="showETC">
            <div class="popup-box">
                <KeyInput ref="childKey" @handleConfirmBtnFn="handleConfirmBtnFn" :plateNumber="plateNumber"/>
                <div class="popup-btn">
                    <van-button type="primary" @click="handleDefine">确 定</van-button>
                    <van-button color="#9e9e9e" type="default" @click="handleClose">取 消</van-button>
                </div>
                <div class="header-style-text">
                    <span class="p-text2">温馨提示：</span>
                    <p class="p-text2">授权车场对安装ETC的车辆在出场时实现自动扣费；<span style="color: red;">请注意，如果您的车辆没有ETC的话出场会变慢，请按照实际情况操作。</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Keyboard from '../components/Numkey';
    import KeyInput from '../components/KeyInput';
    import {Button, Picker, Toast, Grid, GridItem, Dialog, Popup, Icon, Swipe, SwipeItem} from 'vant';
    import {centerParkingList, etcOpen, getAdvertisement, getParking, longCarList} from "../api/interface";
    import Vue from 'vue';
    import {Lazyload} from 'vant';

    Vue.use(Lazyload);

    export default {
        name: 'home',
        components: {
            Keyboard,
            KeyInput,
            [Button.name]: Button,
            [Picker.name]: Picker,
            [Grid.name]: Grid,
            [GridItem.name]: GridItem,
            [Popup.name]: Popup,
            [Icon.name]: Icon,
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem,
            [Dialog.Component.name]: Dialog.Component,
        },
        data() {
            return {
                result: "",
                columns: [],
                shadow: [],
                show: false,
                carNumber: "",
                showPop: false,
                adImg: [],
                targeType: "",
                target: "",
                showETC: false,
                plateNumber: "",
            };
        },
        created() {
            this.columns = JSON.parse(localStorage.getItem("licensePlate")) || [];
            if (this.columns.length > 3) {
                this.shadow = new Array(6);
            } else if (this.columns.length > 6) {
                this.shadow = new Array(9);
            } else {
                this.shadow = new Array(3);
            }
        },
        mounted() {
            this.advertisement();
            this.$route.query.parkingId ? sessionStorage.setItem("parkingId", this.$route.query.parkingId.toString()) : this.$route.query.parkingId;
            getParking(this.$route.query.parkingId).then((res) => {
                sessionStorage.setItem('projectId', res.data.data.projectId);
            })
        },
        methods: {
            advertisement() {
                let param = {
                    parkingId: this.$route.query.parkingId ? this.$route.query.parkingId : parseInt(sessionStorage.getItem("parkingId")),
                    location: 0
                }
                getAdvertisement(param).then(res => {
                    if (res.data.code == 'SUCCESS') {
                        if (res.data.data.materias.length > 0) {
                            this.showPop = true;
                        }
                        this.adImg = res.data.data.materias;
                        this.targeType = res.data.data.targeType;
                        this.target = res.data.data.target;
                    }
                })
            },
            handleClick() {
                if (this.targeType == 0) {
                    window.location.href = this.target;
                }
            },
            confirmBtnFn(val) {
                this.result = val.replace(/(^\s+)|(\s+$)/g, "");
                this.result = this.result.replace(/\s/g, "");
                this.show = true;
            },
            getUserCenterList() {
                if (this.$route.query.parkingId || parseInt(sessionStorage.getItem("parkingId"))) {
                    this.$refs.child.confirmBtnFn();
                    let prama = {
                        carNumber: this.result,
                        parkingId: this.$route.query.parkingId ? this.$route.query.parkingId : parseInt(sessionStorage.getItem("parkingId"))
                    }
                    centerParkingList(prama).then((res) => {
                        var dataInfo = res.data.data;
                        if (res.data.code == 'SUCCESS') {
                            this.$router.push({
                                path: '/temporary', query: {
                                    parkingId: dataInfo.parkingId,
                                    parkingName: dataInfo.parkingName,
                                    roadName: dataInfo.roadName,
                                    licensePlate: dataInfo.licensePlate,
                                    entranceTime: dataInfo.entranceTime,
                                    amount: dataInfo.amount,
                                    discountAmount: dataInfo.discountAmount,
                                    couponType: dataInfo.couponType,
                                    couponId: dataInfo.couponId,
                                    orderNo: dataInfo.orderNo,
                                    roadId: dataInfo.roadId,
                                    preferential: dataInfo.preferential,
                                    realAmount: dataInfo.realAmount,
                                    parkingDuration: dataInfo.parkingDuration,
                                    type: 1,
                                    title: '场内缴费'
                                }
                            });
                            if (!this.result) return;
                            //判断之前有没有搜索的本地存储
                            if (!localStorage.getItem("licensePlate")) {
                                //没有,就设置数组，但是字符串类型
                                localStorage.setItem("licensePlate", JSON.stringify(this.result));
                            } else {
                                //之前有存储，就获取
                                this.columns = JSON.parse(localStorage.getItem("licensePlate"));
                            }
                            //不管之前有没有，都要给数组增加数据
                            this.columns.unshift(this.result);
                            //ES6去重
                            let newArr = new Set(this.columns);
                            let licensePlateList = Array.from(newArr);
                            if (licensePlateList.length > 10) {
                                licensePlateList.splice(10, 1);
                            }
                            localStorage.setItem("licensePlate", JSON.stringify(licensePlateList));
                            this.columns = JSON.parse(localStorage.getItem("licensePlate"));
                            if (this.columns.length > 3) {
                                this.shadow = new Array(6);
                            } else if (this.columns.length > 6) {
                                this.shadow = new Array(9);
                            } else {
                                this.shadow = new Array(3);
                            }
                        }
                    })
                } else {
                    Dialog.alert({
                        title: "温馨提示",
                        message: "请扫描二维码进行临停车辆支付！",
                        confirmButtonColor: '#FE4D13'
                    }).then(() => {
                        // on close
                        /*

                        * */
                    });
                }
            },
            onConfirm(value, index) {
                // console.log(`当前值：${value}, 当前索引：${index}`);
                this.carNumber = value
            },
            handleClickETC() {
                this.showETC = true;
            },
            handleConfirmBtnFn(val) {
                this.plateNumber = val.replace(/(^\s+)|(\s+$)/g, "");
                this.plateNumber = this.plateNumber.replace(/\s/g, "");
            },
            handleClose() {
                this.$refs.childKey.handleConfirmBtnFn();
                this.showETC = false;
                this.plateNumber = "";
            },
            handleDefine() {
                this.$refs.childKey.handleConfirmBtnFn();
                let param = {
                    licensePlate: this.plateNumber,
                    open: true
                }
                etcOpen(param).then(res => {
                    this.showETC = false;
                    this.plateNumber = "";
                    if (res.data.code == 'SUCCESS') {
                        Toast.success('开通成功！');
                    }
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            // 添加背景色
            document.querySelector('body').setAttribute('style', 'background-color:#F9F9F9')
            next()
        },
        beforeRouteLeave(to, from, next) {
            // 去除背景色
            document.querySelector('body').setAttribute('style', '')
            next()
        }
    }
</script>
<style lang="less" scoped>
    .container {
        width: 100vw;
        height: 100vh;
        background: #fff;
        display: flex;
    }

    .home {
        background: url("../assets/img/home.jpg");
        /* 当使用该设置时（方法一），当可视高度不变时，可视宽度变化时，背景图片的高度也会自适应变化 */
        background-size: contain;
        background-repeat: no-repeat;
        /* 把背景图片固定住 */
        background-attachment: fixed;
        width: 100%;
    }

    .header-style-bg {
        background-color: #fbfbfb;
        border-radius: 10px;
        margin: 105px 15px 10px 15px;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .cx {
        width: 302px;
        background: linear-gradient(-30deg, #DC5040, #EC7955);
        border-radius: 5px;
        border: none;
        font-weight: 500;
        margin: 20px 0;
    }

    .overlay-box {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .2);
    }

    .popup-box {
        position: fixed;
        top: 14%;
        background-color: #FFFFFF;
        border-radius: 10px;
        margin: 0 15px 10px 15px;
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
    }

    .popup-box {
        /deep/ .keyboard-wrap {
            z-index: 1;
        }
    }

    .popup-btn {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
    }

    .popup-btn .van-button {
        width: 100px;
        border-radius: 5px;
        border: none;
        font-weight: 500;
        margin: 20px 10px;
    }

    .title {
        font-size: 12px;
        text-align: left;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
    }

    .license-plate {
        padding: unset;

        /deep/ .cph-wrap-shadow {
            margin: 2px auto;
        }
    }

    /deep/ .cph-box {
        background-color: unset;
        z-index: 0;
    }

    .header-style-text {
        display: flex;
        flex-direction: column;
        align-items: start;
        color: #8a8a8a;
        margin: 0 33px;
        line-height: 25px;
    }

    .p-text2 {
        font-size: 12px;
        text-align: left;
    }

    .bottom-style-text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4%;
    }

    .bottom-style-text .p-text2 {
        font-family: 微软雅黑;
        font-size: 12px;
        color: #666666;
    }

    /deep/ .van-grid-item__content {
        background-color: #F9F9F9 !important;
    }

    /deep/ .van-picker__mask {
        background-image: linear-gradient(180deg, #F9F9F9, hsla(0, 0%, 100%, .4)), linear-gradient(0deg, #F9F9F9, hsla(0, 0%, 100%, .4)) !important;
    }

    /deep/ .van-overlay {
        background-color: rgba(0, 0, 0, .2);
        backdrop-filter: blur(6px);
    }

    /deep/ .van-popup--center {
        width: 100%;
    }

    .ad-popup {
        background: none !important;
    }

    .ad-popup img {
        width: 70%;
    }
</style>

